import { EstadoSubConvocatoria, EventoViajeCancerbero, Viaje } from '@obrador/api-interfaces';
import { GridsterConfig } from 'angular-gridster2';
import { GridsterItem } from 'angular-gridster2/lib/gridsterItem.interface';

export type ViajeGridItem = Viaje & GridsterItem & { selected: boolean, tooltip: string, parent: ConvocatoriaData, tieneProblema: boolean };

export interface ConvocatoriaData {
    id: number;
    position: string | number;
    motorizado: string;
    remolque: string;
    chofer: string;
    empresa: string;
    familia_servicio: string;
    viaje: Viaje;
    viajes?: Array<ViajeGridItem>;
    estado: EstadoSubConvocatoria,
    gridOptions: GridsterConfig;
    id_convocatoria?: string | number;
    id_sub_convocatoria?: string | number;
    canSelect: boolean;
    terminado: boolean;
    cannotSelectTooltip?: string;
    isPendiente: boolean;
    fecha_inicio: Date;
    fecha_fin: Date;
    expanded: boolean; 
    tieneRemolque: boolean;
    tieneMensajesProgramados: boolean;
    ultimoEventoCancerbero: EventoViajeCancerbero;
    isLoading?: boolean
}
