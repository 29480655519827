import { BaseDTO } from './api-interfaces';
import { ControlReserva } from './control-desvio';
import { Convocatoria } from './convocatoria';
import { EventoValidacion, Regla, ReglaInputReserva } from './regla';
import { FamiliaServicio } from './servicio';
import { Problema, Viaje, ViajeRegistro } from './viaje';
import { SistemaOrigen, TipoCierreReserva, TipoEventoReserva } from './enums';
import { AdminDTO } from './admin';
import { RemitoDigital } from './remito-digital';
import { TipoAlarma } from "./alarma";
import {Requerimiento} from "./requerimiento";


export interface PlanViaje extends BaseDTO {
    fecha: Date;
    cantidad: number;
    cantidad_camiones: number;
    total_viajes: number;
    reserva?: Reserva;
    id_reserva?: number;
}

export interface ResumenEstadoViaje {
    pendientes: number;
    enCurso: number;
    completos: number;
    totales: number;
}

export interface ResumenViajes {
    dia: ResumenEstadoViaje;
    total: ResumenEstadoViaje;
}

export interface Reserva extends BaseDTO {

    id_migrador: number;

    doc_id: string;

    cliente_rut: string;

    empresa: string;

    nombre_servicio: string;

    nombre_producto: string;

    gaudi2_ot: string;

    gaudi2_servicio?: number;

    origen: string;

    destino: string;

    booking: string;

    finalizada: boolean;

    aprobada: boolean;

    en_curso: boolean;

    fecha_inicio: Date;

    fecha_fin: Date;

    depto_origen: string;

    depto_destino: string;

    paraje_origen: string;

    paraje_destino: string;

    unidades: string;

    kms: number;

    kms_dia: number;

    toneladas: number;

    tarifa_fletero: number;

    tarifa_distribuidor: number;

    tarifa_moneda: string;

    tarifa_costo_variable: number;

    fecha_semana: string;

    fecha_mes: string;

    tiempo_total: number;

    hash: string;

    envase: string;

    modalidad: string;

    origen_id: number;

    destino_id: number;

    envase_id: number;

    modalidad_id: number;

    segmento_id: number;

    remolque_volcadora_fijo: string;

    modelo_viaje: number;

    productos_bookings: string;

    tarifa_composicion_flota: string;

    cerrada: boolean;

    bookings: string;
    // TODO: completar estos cuando sea necesario.
    // horario_carga: Horario;
    // horario_descarga: Horario;
    cola_viajes: ColaViajes;
    // Relaciones
    viajes?: Array<Viaje>;
    familia_servicio?: FamiliaServicio;
    // armados?: Array<ArmadoDTO>;
    convocatorias?: Array<Convocatoria>;
    planViajes?: Array<PlanViaje>;
    resumenViajes: ResumenViajes;
    planDiario?: PlanViaje;
    isModalidadContenedor?: boolean;
    reglas?: Array<Regla>;
    reglaCantidades?: Regla;
    reglaInputReservas?: Array<ReglaInputReserva>;
    cierre_cantidades?: boolean;
    eventos?: EventoReserva[];
    completa?: boolean;
    operada_hoy?: boolean;
    con_pendientes?: boolean;
    comentario_cierre_cantidades?: string;
    comentario_operacion?:string;
    ayuda_viaje?: string;
    origen_q_cierre?: TipoCierreReserva;
    tiempo?: Tiempo;
    tipos_alarmas?: TipoAlarma[];
    requerimientos?: Requerimiento[];
    control_reserva?: ControlReserva;
}

export interface Tiempo {
    ida?: number;
    origen?: number;
    vuelta?: number;
    destino?: number;
    imprevistos?: number;
}

export interface ControlIntraViaje {
    descripcion: string;
    tipo: string;
    tolerancia: number;
}

export interface ColaViajes {
    viajes_total: number;
    viajes_avance: number;
}

export interface ResumenReserva {
    id: number;
    motorizado: string;
    remolque: string;
    chofer: string;
    inicio: Date;
    fin: Date;
    toneladasOrigen: number;
    toneladasDestino: number;
    remitoOrigen: string[];
    remitoDestino: string[];
    fotos: string[];
    contenedores: Array<{ id: number, numero_contenedor: string, url?: string }>;
    estados: string[];
    remitos: string[];
    cierre_cantidades: boolean
    reserva: Partial<Reserva>;
    data: Partial<Viaje>;
    problema?: Problema;
    eventos_validacion?: Array<EventoValidacion>;
    valido: boolean;
    remitos_digitales?: RemitoDigital[];
    viaje_registro?: ViajeRegistro;
}

export interface ResumenCantidadReserva {
    cantidadOrigen: number;
    cantidadDestino: number;
    cantidadReserva: number;
}

export interface ResumenCantidadViajes {
    completos: number;
    incompletos: number;
    totalViajes: number;
    totalReserva: number;
    cantidadRemitos?: number;
}

export interface ResumenReservaData {
    cantidadesReserva?: ResumenCantidadReserva;
    cantidadesViajes?: ResumenCantidadViajes;
    viajes: ResumenReserva[]
}

export interface EventoReserva extends BaseDTO {
    fecha: Date;
    tipo: TipoEventoReserva;
    origen?: SistemaOrigen;
    id_usuario?: string;
    metadata?: string;
    metadata_anterior?: string;
    reservaId: number;
    planViajeId?: number;
    adminId?: number;
    admin?: AdminDTO;
}

export interface UpdatePesadasByReserva {
    numero: string;
    entrada: string | Date;
    salida: string;
    motorizado: string | Date;
    remolque: string;
}
