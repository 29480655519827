import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
    ControlReserva,
    EstadoViaje,
    Reserva,
    ResultDTO,
    ResumenReservaData,
    TipoCierreReserva,
    UpdatePesadaItem,
    UpdatePesadaItemResult,
    Viaje
} from '@obrador/api-interfaces';
import { ReservaUtils } from '@obrador/common';
import { endOfDay, startOfDay } from 'date-fns';
import { isBoolean } from 'lodash';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedResult, QueryParams } from '../rest.model';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class ReservaService extends RestService<Reserva>{

    constructor(protected injector: Injector) {
        super(injector, Resource.RESERVAS);
    }

    getReservas(fecha: Date, incluirCerradas: boolean, cuentaId: number): Observable<PagedResult<Reserva>> {
        let params = new HttpParams()
            .set('from', fecha.toISOString())
            .set('cierre_cantidades', false)
            .set('limit', 1000)
        if (isBoolean(incluirCerradas)) {
            params = params.set('incluir_cerradas', incluirCerradas);
        }
        if (cuentaId) {
            params = params.set('cuentaId', cuentaId);
        }
        
        return this.http.get<PagedResult<Reserva>>(this.buildURL(), { params })
    }

    getReservasIncluyendoCerradas(fecha: Date): Observable<PagedResult<Reserva>> {
        const params = new HttpParams()
            .set('from', fecha.toISOString())
            .set('limit', 1000)
            .set('incluir_cerrados', true);
        return this.http.get<PagedResult<Reserva>>(this.buildURL(), { params })
    }

    paginatedPanel(queryParams: QueryParams): Observable<PagedResult<Reserva>> {
        return this.http.get<PagedResult<PagedResult<Reserva>>>(this.buildURLPanel(), { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapPaginated(data, queryParams))
            );
    }

    getReservasConPlanes(from: Date): Observable<Array<Reserva>> {
        const params = new HttpParams().set('from', from.toISOString());
        return this.http.get<Array<Reserva>>(`${this.buildURLPlanes()}`, { params });
    }

    protected buildURLPlanes(): string {
        return `${this.baseURL}/${this.resource}/${Resource.PLAN_VIAJE}`;
    }

    protected buildURLPanel(): string {
        return `${this.baseURL}/${this.resource}/panel`;
    }

    protected buildURLResumen(id: number): string {
        return `${this.baseURL}/${this.resource}/${id}/resumen`;
    }

    mapEntity(value: Reserva): Reserva {
        if (value) {
            value.isModalidadContenedor = ReservaUtils.isReservaModalidadcontenedor(value);
        }
        return value;
    }

    getResumenReserva(id: number): Observable<ResumenReservaData> {
        return this.http.get<ResumenReservaData>(this.buildURLResumen(id))
            .pipe(
                catchError(err => this.handleError(err))
            );
    }

    cerrar(id: number, comentario?: string): Observable<Reserva> {
        return this.http.patch<Reserva>(`${this.buildURL()}/${id}/cerrar`, {
            comentario
        }).pipe(
            catchError(err => this.handleError(err))
        );
    }

    
    getReservasActivas(fecha: Date, cuentaId: number, id_familia?: number): Observable<Reserva[]> {
        let params = null;
        if (fecha) {
            const from = startOfDay(fecha);
            const to = endOfDay(fecha);
            params = new HttpParams()
                .set('from', from.toISOString())
                .set('to', to.toISOString())
        }
        if (cuentaId) {
            if (params) {
                params = params.set('cuentaId', cuentaId);
            } else {
                params = new HttpParams().set('cuentaId', cuentaId)
            }
        }
        if (id_familia) {
            params = params ? params.set('id_familia', id_familia) : new HttpParams().set('id_familia', id_familia);
        }
        return this.http.get<Reserva[]>(`${this.buildURL()}/activas`, { params })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapArray(data)
            ))
    }

    getReservaActiva(id: number): Observable<Reserva> {
        return this.http.get<Reserva>(`${this.buildURL()}/get-activa/${id}`)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapEntity(data)
                ))
    }

    updateQ(id: number, q: TipoCierreReserva): Observable<Reserva> {
        return this.http.put<Reserva>(`${this.buildURL()}/${id}/update-q`, {
            q_seleccionado: q
        });
    }

    tieneViajesPendientes(id: number): Observable<boolean> {
        return this.http.get<ResultDTO>(`${this.buildURL()}/${id}/tiene-viajes-pendientes`)
            .pipe(
                map(response => response.result)
            )
    }

    getControlReserva(id: number): Observable<ControlReserva> {
        return this.http.get<ControlReserva>(`${this.buildURL()}/${id}/${Resource.CONTROL_RESERVA}`)
            .pipe(
                catchError(err => this.handleError(err))
            );
    }

    getViajesReserva(id_reserva: number, estados?: EstadoViaje[]): Observable<Array<Viaje>> {
        return this.http.get<Viaje[]>(`${this.buildURL()}/${id_reserva}/viajes`, {
            params: this.buildParams({
                filters: [{ field: 'estados', value: estados }]
            })
        })
    }

    createPesadas(id_reserva: number, pesadas: UpdatePesadaItem[]): Observable<UpdatePesadaItemResult[]> {
        return this.http.post<UpdatePesadaItemResult[]>(`${this.buildURL()}/${id_reserva}/pesadas`, {
            pesadas
        });
    }

}
