import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import {
    AccordionAnchorDirective,
    AccordionDirective,
    AccordionLinkDirective,
    AlertMessageComponent,
    AutocompleteEmpresaComponent,
    DialogAlertComponent,
    DialogComentarioComponent,
    CambiarHorarioModalComponent,
    DialogConfirmComponent,
    DialogViajeApiComponent,
    DialogViajeRechazadoComponent,
    EmptyListWarnComponent,
    FormErrorDirective,
    FormErrorsComponent,
    FormInfiniteAutocompleteComponent,
    FormInfiniteAutocompleteDirective,
    LoadingComponent,
    MatSelectAllComponent,
    PlanDiarioCardComponent,
    RecursosConvocatoriaComponent,
    ReservaCardComponent,
    ReservaSmallCardComponent,
    SpinnerComponent,
    TableFilterFamiliaComponent,
    DialogComentarioGenericoComponent,
    DataTableCellPipe,
    DataTableComponent,
    DataTableClass,
    FormGroupSelectComponent,
    HistoricoValidacionDialogComponent,
    HistoricoNovedadesComponent,
    NumericCardComponent,
    EditableTextComponent,
    DialogComentarioReservaComponent
} from './components';
import {
    DigitOnlyDirective,
    FallbackDirective,
    NgForTrackByIdDirective,
    NgForTrackByIndexDirective,
    NgForTrackByPropertyDirective,
    NgForTrackByValueDirective,
} from './components/directives';
import { RecursoMotorizadoConductorComponent } from './components/recursos-convocatoria/recurso-motorizado-conductor/recurso-motorizado-conductor.component';
import { RecursoMotorizadoItemComponent } from './components/recursos-convocatoria/recurso-motorizado-item/recurso-motorizado-item.component';
import { LoadingController, ProgressContainerComponent } from './controllers';
import { ErrorMessages } from './error';
import { MediaPreviewOverlayService, MediaViewerComponent } from './overlay';
import {
    EnumPipe,
    GroupByPipe,
    ReservaPipe,
    ShortNumberPipe,
    TrackByFnPipe,
    ToneladasPipe,
    ConductorStrPipe,
    NestedObjectPipe,
    DateDiffPipe,
    MinutesToHoursPipe,
    PercentagePipe,
    ReglaResultIconPipe,
    ControlDesvioGrupoPipe,
} from './pipes';
import {
    EnumSelectModel,
    EnumTranslateService,
    LocalStorageService,
    MenuItems,
    ResumenReservaHelper,
    SideBarService,
    CuentaSelectService,
    SnackBarService,
    ApiErrorMessageService,
} from './services';
import { NgxTimelineModule } from '@frxjs/ngx-timeline';
import { ApiErrorPipe } from './pipes/api-error.pipe';

const COMPONENTS = [
    AutocompleteEmpresaComponent,
    EmptyListWarnComponent,
    SpinnerComponent,
    FormInfiniteAutocompleteComponent,
    FormErrorsComponent,
    ProgressContainerComponent,
    AlertMessageComponent,
    DialogConfirmComponent,
    PlanDiarioCardComponent,
    RecursosConvocatoriaComponent,
    DialogAlertComponent,
    DialogViajeApiComponent,
    TableFilterFamiliaComponent,
    LoadingComponent,
    DialogViajeRechazadoComponent,
    LoadingComponent,
    ReservaCardComponent,
    ReservaSmallCardComponent,
    MatSelectAllComponent,
    MediaViewerComponent,
    CambiarHorarioModalComponent,
    DialogComentarioComponent,
    DialogComentarioGenericoComponent,
    DataTableComponent,
    FormGroupSelectComponent,
    HistoricoValidacionDialogComponent,
    HistoricoNovedadesComponent,
    NumericCardComponent,
    EditableTextComponent,
    DialogComentarioReservaComponent
];

const PIPES = [
    EnumPipe,
    GroupByPipe,
    ReservaPipe,
    ToneladasPipe,
    ShortNumberPipe,
    TrackByFnPipe,
    ConductorStrPipe,
    DataTableClass,
    DataTableCellPipe,
    NestedObjectPipe,
    DateDiffPipe,
    MinutesToHoursPipe,
    PercentagePipe,
    ToneladasPipe,
    ReglaResultIconPipe,
    ControlDesvioGrupoPipe,
    ApiErrorPipe
];

const DIRECTIVES = [
    AccordionDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    FormInfiniteAutocompleteDirective,
    FormErrorDirective,
    FallbackDirective,
    DigitOnlyDirective,
    NgForTrackByIdDirective,
    NgForTrackByPropertyDirective,
    NgForTrackByIndexDirective,
    NgForTrackByValueDirective,
];

const PROVIDERS = [
    EnumTranslateService,
    ErrorMessages,
    MenuItems,
    LoadingController,
    SnackBarService,
    SideBarService,
    LocalStorageService,
    EnumSelectModel,
    MediaPreviewOverlayService,
    ResumenReservaHelper,
    ApiErrorMessageService,
    ResumenReservaHelper
];

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        MatAutocompleteModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatCardModule,
        MatTabsModule,
        MatSnackBarModule,
        MatListModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatChipsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        MatBadgeModule,
        MtxSelectModule,
        // MzdTimelineModule,
        NgxTimelineModule,
    ],
    providers: [...PROVIDERS, ...PIPES, DecimalPipe, DatePipe],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
        // Internos
        RecursoMotorizadoItemComponent,
        RecursoMotorizadoConductorComponent
    ],
    exports: [...COMPONENTS, ...PIPES, ...DIRECTIVES, FlexLayoutModule],
})
export class SharedModule {}
