<div mat-dialog-title class="dialog-title-close">
    <span>Detalle de viaje</span>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <ng-container *ngIf='!isLoading && data'>
        <div class='dialog-viaje-subtitle'>
            Identificadores
        </div>
        <mat-divider></mat-divider>
        <div class='dialog-viaje-info-row'>
            <mat-icon matTooltip='ID'>tag</mat-icon>
            <span class='dialog-viaje-id-info'>
                {{ data.id }}
                <ng-container *ngIf='data.viaje_api.finalizado; else sinFinalizar'>
                 <mat-chip-listbox>
                    <mat-chip-option class='dialog-viaje-chip-finalizado'>
                        <span>Finalizado</span>
                    </mat-chip-option>
                 </mat-chip-listbox>
                </ng-container>
                <ng-template #sinFinalizar>
                    <mat-chip-listbox>
                        <mat-chip-option class='dialog-viaje-chip-curso'>
                            <span>Sin finalizar</span>
                        </mat-chip-option>
                    </mat-chip-listbox>
                </ng-template>
            </span>
        </div>
        <div class='dialog-viaje-info-row'>
            <span class='dialog-viaje-id-info'>
            <mat-icon matTooltip='App ID'>
                smartphone
            </mat-icon>
            <span>{{ data.viaje_api.document_id }}</span>
            <ng-container *ngIf='data.operativo===false'>
                <mat-chip-listbox>
                   <mat-chip-option class='dialog-viaje-chip-no-operativo'>
                       <span>No Operativo</span>
                   </mat-chip-option>
                </mat-chip-listbox>
               </ng-container> 
            </span>
        </div>
        <div class='dialog-viaje-info-row'  *ngIf='usuarioApp'>
            <mat-icon matTooltip='Usuario App'>
                account_circle
            </mat-icon>
            <span>{{ usuarioApp.display_name || usuarioApp.email }}</span>
        </div>
        <ng-container *ngIf='createdBy?.admin'>
            <div class='dialog-viaje-subtitle'>
                Creado
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-info-row-multi'>
                <div class='dialog-viaje-info-row-multi-row'>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ createdBy.admin.nombre }} {{ createdBy.admin.apellido }} </span>
                </div>
                <div class='dialog-viaje-info-row-multi-row'>
                    <mat-icon>email</mat-icon>
                    <span> {{ createdBy.admin.email }} </span>
                </div>
            </div>
            <div class='dialog-viaje-info-row'>
                <mat-icon>event</mat-icon>
                <span> {{ createdBy.fecha | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf='validatedBy?.admin'>
            <div class='dialog-viaje-subtitle'>
                Validado
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-info-row-multi'>
                <div class='dialog-viaje-info-row-multi-row'>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ validatedBy.admin.nombre }} {{ validatedBy.admin.apellido }} </span>
                </div>
                <div class='dialog-viaje-info-row-multi-row'>
                    <mat-icon>email</mat-icon>
                    <span> {{ validatedBy.admin.email }} </span>
                </div>
            </div>
            <div class='dialog-viaje-info-row'>
                <mat-icon>event</mat-icon>
                <span> {{ validatedBy.fecha | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf='data.viaje_api.problema'>
            <div class='dialog-viaje-subtitle dialog-viaje-problema'>
                Problema
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-info-row dialog-viaje-info-problema'>
                <mat-icon>error</mat-icon>
                <span [attr.user]='!!data.viaje_api.problema.usuario_app' [matTooltip]='data.viaje_api.problema.usuario_app | conductorStr'
                >{{ data.viaje_api.problema.comentario }} </span>
            </div>
            <div class='dialog-viaje-info-row dialog-viaje-info-problema'>
                <mat-icon>event</mat-icon>
                <span>{{ data.viaje_api.problema.fecha | date: 'dd/MM/yyyy HH:mm' }} </span>
            </div>
        </ng-container>
        <div class='dialog-viaje-subtitle'>
            Calendario
        </div>
        <mat-divider></mat-divider>
        <div class='dialog-viaje-calendar-subtitle'>
            <span>APP</span>
            <span>Obrador</span>
        </div>
        <div class='dialog-viaje-calendar-row'>
            <div class='dialog-viaje-info-row'>
                <mat-icon>today</mat-icon>
                <span> {{ data.viaje_api.fecha_inicio | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
            <div class='dialog-viaje-info-row'>
                <mat-icon class='dialog-viaje-obrador-data'>today</mat-icon>
                <span> {{ data.fecha_inicio | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
        </div>
        <div class='dialog-viaje-calendar-row'>
            <div class='dialog-viaje-info-row'>
                <mat-icon>event</mat-icon>
                <span *ngIf='data.viaje_api.fecha_fin; else sinFechaFin'>
                    {{ data.viaje_api.fecha_fin | date: 'dd/MM/yyyy HH:mm'}}
                </span>
                <ng-template #sinFechaFin>
                    <span><i>Sin fecha fin</i></span>
                </ng-template>
            </div>
            <div class='dialog-viaje-info-row'>
                <mat-icon class='dialog-viaje-obrador-data'>event</mat-icon>
                <span> {{ data.fecha_fin | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
        </div>
        <ng-container *ngIf='data.reserva'>
            <div class='dialog-viaje-subtitle'>
                Reserva
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-info-row-multi'>
                <div class='dialog-viaje-info-row-multi-row' matTooltip="Reserva">
                    <mat-icon>assignment_turned_in</mat-icon>
                    R{{ data.reserva.id_migrador }}
                </div>
                <div class='dialog-viaje-info-row-multi-row' matTooltip="Booking">
                    <mat-icon>place</mat-icon>
                    <span> {{ data.reserva.booking }} </span>
                </div>
                <div class='dialog-viaje-info-row-multi-row' *ngIf='data.reserva.origen_q_cierre'>
                    <mat-icon matTooltip='Origen Q cierre'>info</mat-icon>
                    <span> {{ data.reserva.origen_q_cierre | enum: 'TipoCierreReserva' }}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf='data.viaje_api.estados?.length > 0'>
            <div class='dialog-viaje-subtitle'>
                Estados
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-estados'>
                <ng-container *ngFor='let estado of data.viaje_api.estados; trackByIndex'>
                    <div class='dialog-viaje-estados-item' [attr.borrado]='estado.borrado'>
                        <mat-icon>calendar_today</mat-icon>
                        <span>{{ estado.fecha | date: 'dd/MM/yyyy HH:mm'}}</span>
                        <span [attr.estadoViaje]='estado.tipo_estado.id'
                              [attr.user]='!!estado.usuario_app'
                              [matTooltip]='estado.usuario_app | conductorStr'
                        > {{ estado.tipo_estado.nombre }}</span>
                        <span class='error-text' *ngIf='estado.borrado'>
                            (borrado)
                        </span>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf='data.viaje_api.remitos?.length > 0'>
            <div class='dialog-viaje-subtitle'>
                Remitos
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-estados'>
                <div class='dialog-viaje-estados-grouped-item' *ngFor='let remito of data.viaje_api.remitos; trackByIndex'>
                    <div>
                        <mat-icon>monetization_on</mat-icon>
                        <span [attr.user]='!!remito.usuario_app' [matTooltip]='remito.usuario_app | conductorStr'>
                            {{ remito.numero || 'Sin número' }}
                        </span>
                    </div>
                    <div>
                        <mat-icon>calendar_today</mat-icon>
                        <span> {{ remito.fecha | date: 'dd/MM/yy HH:mm'}}</span>
                    </div>
                    <div>
                        <mat-icon>image</mat-icon>
                        <a class='link-primary' [href]='remito.url' target='_blank' rel='noopener' *ngIf='remito.url; else sinUrl'>Link</a>
                        <ng-template #sinUrl>
                            <a class='link-primary'></a>
                        </ng-template>
                    </div>
                    <div>
                        <mat-icon>scale</mat-icon>
                        <span>{{ +remito.kilos_netos | toneladas }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf='data.viaje_api.fotos?.length > 0'>
            <div class='dialog-viaje-subtitle'>
                Fotos
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-estados'>
                <div *ngFor='let foto of data.viaje_api.fotos; trackByIndex' class='dialog-viaje-estados-grouped-item'>
                    <div>
                        <mat-icon>photo</mat-icon>
                        <a class='link-primary'
                           [matTooltip]='foto.usuario_app | conductorStr'
                           [href]="foto.url" target='_blank' rel='noopener'>Link</a>
                    </div>
                    <div>
                        <mat-icon>calendar_today</mat-icon>
                        <span> {{ foto.fecha | date: 'dd/MM/yy HH:mm'}}</span>
                    </div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf='data.viaje_api.contenedores?.length > 0'>
            <div class='dialog-viaje-subtitle'>
                Contenedores
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-estados'>
                <div *ngFor='let contenedor of data.viaje_api.contenedores; trackByIndex' class='dialog-viaje-estados-grouped-item'>
                    <div>
                        <mat-icon (click)='openURL(contenedor.url)' svgIcon='container'>
                        </mat-icon>
                        <span  [attr.user]='!!contenedor.usuario_app' [matTooltip]='contenedor.usuario_app | conductorStr'>
                            {{ contenedor.numero_contenedor }}
                        </span>
                        <span class='dialog-viaje-span-img' *ngIf='contenedor.url'>
                            <mat-icon>photo</mat-icon>
                            <a class='link-primary' [href]='contenedor.url' target='_blank' rel='noopener'>Link</a>
                        </span>
                        <span class='dialog-viaje-span-img'>
                            <mat-icon>today</mat-icon>
                            {{ contenedor.fecha |  date: 'dd/MM/yyyy HH:mm' }}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- Informaciones -->
        <ng-container *ngIf='data.viaje_api.informaciones?.length > 0'>
            <div class='dialog-viaje-subtitle'>
                Información
            </div>
            <mat-divider></mat-divider>
            <div class='dialog-viaje-estados'>
                <div class='dialog-viaje-estados'>
                    <ng-container *ngFor='let informacion of data.viaje_api.informaciones; trackByIndex'>
                        <div class='dialog-viaje-estados-item dialog-viaje-estados-info'>
                            <mat-icon>calendar_today</mat-icon>
                            <span>{{ informacion.fecha | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <mat-icon class='info-icon'>info</mat-icon>
                            <span [attr.user]='!!informacion.usuario_app'
                                    [matTooltip]='informacion.usuario_app | conductorStr'>
                                {{ informacion.valor }}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf='data.viaje_api.pesadas?.length > 0'>
            <div class='dialog-viaje-subtitle'>
                Pesadas
            </div>
            <div class='dialog-viaje-estados'>
                <div *ngFor='let pesada of data.viaje_api.pesadas; trackByIndex' class='dialog-viaje-estados-grouped-item'>
                    <mat-icon>monitor_weight</mat-icon>
                    <span> {{ pesada.numero }} </span>
                    <mat-icon matTooltip='Borrar Pesada' (click)='borrarPesada(pesada)' class='icon-danger'>delete</mat-icon>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<div mat-dialog-actions class='justify-content-end'>
    <button mat-button color='accent' *ngIf='tieneNovedades' (click)='openModalNovedades(data)'>
        Novedades
    </button>
    <button mat-button color='accent' *ngIf='tieneValidaciones' (click)='openModalValidaciones(data.viaje_api.id)'>
        Validaciones
    </button>
    <button color='primary' mat-button (click)="close()" cdkFocusInitial>Cerrar</button>
</div>
