import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RestService } from '@obrador/core';
import { lastValueFrom } from 'rxjs';
import { LoadingController } from '../../controllers';
import { SnackBarService } from '../../services';
import { polluteForm } from '../../utils';

export interface DialogComentarioReservaOptions {
    id: number;
    title: string;
    service: RestService<any>;
    key: string;
    comentario?: string;
}

@Component({
    selector: 'app-dialog-comentario-reserva',
    templateUrl: './dialog-comentario-reserva.component.html',
    styleUrls: ['./dialog-comentario-reserva.component.scss']
})
export class DialogComentarioReservaComponent implements OnInit {

    form: FormGroup;
    isEdit: boolean;

    constructor(
        protected dialogRef: MatDialogRef<DialogComentarioReservaComponent>,
        @Inject(MAT_DIALOG_DATA) public options: DialogComentarioReservaOptions,
        protected fb: FormBuilder,
        protected snack: SnackBarService,
        protected loading: LoadingController
    ) {
    }

    ngOnInit(): void {
        if (this.options.comentario) {
            this.isEdit = true;
        }
        this.form = this.fb.group({
            comentario: ['', [Validators.required, Validators.maxLength(1000)]]
        });
        if (this.isEdit) {
            this.form.patchValue({
                comentario: this.options.comentario
            });
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    async submit(): Promise<void> {
        if (this.form.valid) {
            this.loading.start();
            const entity = {
                id: this.options.id,
                [this.options.key]: JSON.stringify(this.form.value.comentario)
            };
            console.log(entity)
            try {
                await lastValueFrom(this.options.service.update(entity));
                this.snack.success('Cambios guardados');
                this.dialogRef.close(true);
            } catch (e) {
                this.snack.unexpected();
            } finally {
                this.loading.stop();
            }
        } else {
            polluteForm(this.form);
        }
    }

}
